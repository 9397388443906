/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Combobox as ComboboxBase, ComboboxButton, ComboboxInput, Label } from '@headlessui/react';
import { useDebounce } from '@uidotdev/usehooks';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loader from 'assets/loading.svg?react';
import { memo, useCallback, useEffect, useState } from 'react';
import { FieldError, FieldErrorsImpl, FieldValues, Merge, useController, UseControllerProps } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { AutocompleteOption } from 'utils/interfaces';
import AutocompleteOptionsDropdown from './AutocompleteOptionsDropdown';
import ErrorMessage from './ErrorMessage';

interface AsyncComboboxProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  options: AutocompleteOption[];
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  error?: Merge<FieldError, FieldErrorsImpl<AutocompleteOption>>;
  loading: boolean;
}

function displayOption(option: AutocompleteOption) {
  return option ? `${option.label} ${option.secondaryLabel ? ` - ${option.secondaryLabel}` : ''}` : '';
}

function AsyncCombobox<T extends FieldValues>({
  options,
  setQuery,
  loading,
  label,
  placeholder,
  error,
  ...formProps
}: AsyncComboboxProps<T>) {
  const [internalQuery, setInternalQuery] = useState('');
  const debouncedInternayQuery = useDebounce(internalQuery, 300);
  const {
    field: { onChange: formOnChange, value: formValue }
  } = useController(formProps);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  const handleInputChange = useCallback(function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInternalQuery(event.target.value);
  }, []);

  const handleComboboxChange = useCallback(
    function handleComboboxChange(option: AutocompleteOption) {
      formOnChange(option);
    },
    [formOnChange]
  );

  useEffect(() => {
    if (debouncedInternayQuery) {
      setQuery(debouncedInternayQuery);
    }
  }, [debouncedInternayQuery, setQuery]);

  return (
    <div className="flex w-full flex-col gap-2">
      <ComboboxBase
        as="div"
        className="relative w-full grow"
        by="id"
        value={formValue as AutocompleteOption}
        onChange={handleComboboxChange}
      >
        {({ open }) => (
          <>
            <Label
              className={twMerge(
                'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                (Boolean(placeholder) || formValue) && '-translate-y-0 text-xs'
              )}
            >
              {label}
            </Label>
            <ComboboxInput
              ref={refs.setReference}
              placeholder={placeholder}
              className="peer h-14 w-full appearance-none rounded-lg border border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md placeholder:text-gray-400 focus:outline-none focus:ring-0"
              onChange={handleInputChange}
              displayValue={displayOption}
            />
            {loading ? (
              <Loader
                aria-hidden="true"
                className="pointer-events-none absolute inset-y-4.5 right-3 z-10 size-5 animate-spin fill-gray-700"
              />
            ) : (
              <ComboboxButton className="absolute inset-y-4.5 right-3 z-10">
                <ChevronDown aria-hidden="true" width={20} height={20} className="fill-gray-700" />
              </ComboboxButton>
            )}
            {open && !loading && debouncedInternayQuery.length > 0 && (
              <AutocompleteOptionsDropdown options={options} floatingStyles={floatingStyles} ref={refs.setFloating} />
            )}
          </>
        )}
      </ComboboxBase>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(AsyncCombobox) as typeof AsyncCombobox;
