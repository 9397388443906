import { QueryClient } from '@tanstack/react-query';
import LoginPage from 'modules/auth/login/LoginPage';
import EmailConfirmationPage from 'modules/auth/modal-pages/EmailConfirmationPage';
import EmailMismatchPage from 'modules/auth/modal-pages/EmailMismatchPage';
import EmailVerificationPage from 'modules/auth/modal-pages/EmailVerificationPage';
import UnknownOrganizationPage from 'modules/auth/modal-pages/UnknownOrganizationPage';
import PasswordResetPage from 'modules/auth/password-reset/PasswordResetPage';
import RegisterPage from 'modules/auth/register/RegisterPage';
import CustomerSupport from 'modules/customerSupport/CustomerSupport';
import DashboardPage from 'modules/dashboard/DashboardPage';
import ConnectedDataSourcesPage from 'modules/dataSources/connectedDataSources/ConnectedDataSourcesPage';
import DataSourceDetailsPage from 'modules/dataSources/dataSourceDetails/DataSourceDetailsPage';
import DataSourcesPage from 'modules/dataSources/dataSources/DataSourcesPage';
import DataSourcesActivationPage from 'modules/dataSources/dataSourcesActivation/DataSourcesActivationPage';
import DataTablesPage from 'modules/dataTables/DataTablesPage';
import AuthGuard from 'modules/layouts/AuthGuard';
import DataSourcesLayout from 'modules/layouts/dataSourcesLayout/DataSourcesLayout';
import DataTablesLayout from 'modules/layouts/dataTablesLayout/DataTablesLayout';
import ErrorLayout from 'modules/layouts/errorLayout/ErrorLayout';
import HeadlineLayout from 'modules/layouts/headlineLayout/HeadlineLayout';
import ReportsLayout from 'modules/layouts/reportsLayout/ReportsLayout';
import SidebarLayout from 'modules/layouts/sidebarLayout/SidebarLayout';
import PublicReportPage from 'modules/reports/publicReport/PublicReportPage';
import ReportPage from 'modules/reports/reportPage/ReportPage';
import ReportsPage from 'modules/reports/reports/ReportsPage';
import Account from 'modules/settings/components/Account';
import Billing from 'modules/settings/components/Billing';
import BiToolIntegration from 'modules/settings/components/BiToolIntegration';
import Organization from 'modules/settings/components/Organization';
import TierPlans from 'modules/settings/components/TierPlans';
import UserManagement from 'modules/settings/components/UserManagement';
import SettingsPage from 'modules/settings/SettingsPage';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { dataSourceDetailsLoader, dataTablesLoader } from 'utils/loaders';
import NewReportPage from './modules/reports/newReport/NewReportPage';

function generateRouter(queryClient: QueryClient) {
  const routes: RouteObject[] = [
    {
      Component: AuthGuard,
      ErrorBoundary: ErrorLayout,
      children: [
        {
          Component: SidebarLayout,
          children: [
            {
              path: '/',
              Component: DashboardPage
            },
            {
              Component: DataSourcesLayout,
              children: [
                {
                  path: '/data-sources/connected',
                  Component: ConnectedDataSourcesPage
                  // loader: dataSourceConfigurationsLoader(queryClient)
                },
                {
                  path: '/data-sources/all',
                  Component: DataSourcesPage
                },
                {
                  path: '/data-sources/activation',
                  Component: DataSourcesActivationPage
                },
                {
                  path: '/data-sources/:dataSourceId/details',
                  Component: DataSourceDetailsPage,
                  loader: dataSourceDetailsLoader(queryClient)
                }
              ]
            },
            {
              // loader: dataTablesLoader(queryClient),
              Component: DataTablesLayout,
              children: [
                {
                  path: '/data-tables/:dataTableId',
                  Component: DataTablesPage
                }
              ]
            },
            {
              Component: ReportsLayout,
              children: [
                {
                  path: '/reports',
                  Component: ReportsPage
                }
              ]
            },
            {
              path: '/reports/new',
              Component: NewReportPage
            },
            {
              path: '/reports/:reportId',
              Component: ReportPage
            },
            {
              Component: SettingsPage,
              children: [
                {
                  path: '/settings/account',
                  Component: Account
                },
                {
                  path: '/settings/organization',
                  Component: Organization
                },
                {
                  path: '/settings/user-management',
                  Component: UserManagement
                },
                {
                  path: '/settings/bi-tool-integration',
                  Component: BiToolIntegration
                },
                {
                  path: '/settings/billing',
                  Component: Billing
                },
                {
                  path: '/settings/tier-plans',
                  Component: TierPlans
                }
              ]
            },
            {
              path: '/customer-support',
              Component: CustomerSupport
            }
          ]
        }
      ]
    },
    {
      Component: HeadlineLayout,
      children: [
        {
          path: '/register',
          Component: RegisterPage
        },
        {
          path: '/email-verification',
          Component: EmailVerificationPage
        },
        {
          path: '/unknown-organization',
          Component: UnknownOrganizationPage
        },
        {
          path: '/email-confirmation',
          Component: EmailConfirmationPage
        },
        {
          path: '/password-reset',
          Component: PasswordResetPage
        },
        {
          path: '/email-mismatch',
          Component: EmailMismatchPage
        },
        {
          path: '/login',
          Component: LoginPage
        }
      ]
    },
    {
      path: '/reports/:reportId/public',
      Component: PublicReportPage
    }
  ];

  return createBrowserRouter(routes);
}

export const queryClient = new QueryClient({ defaultOptions: { queries: { throwOnError: true } } });
export const router = generateRouter(queryClient);
