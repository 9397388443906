import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration } from 'lib/datasource-configuration/datasource-configuration';
import { ProjectConfigurationSchema } from 'lib/model';
import { memo, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import DataSourceCardGridSkeleton from '../components/DataSourceCardGridSkeleton';
import ConnectedDataSourceCard from './components/ConnectedDataSourceCard';

const ConnectedDataSourcesPage = memo(function ConnectedDataSourcesPage() {
  const queryClient = useQueryClient();
  const projectConfiguration = queryClient.getQueryData<ProjectConfigurationSchema>(['projectConfiguration']);
  const { data: dataSourceConfigurations, isPending: isPendingDataSourceConfigurations } =
    useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration(
      projectConfiguration ? projectConfiguration!.id! : '',
      {
        query: {
          queryKey: ['dataSourceConfigurations'],
          staleTime: Infinity,
          enabled: !!projectConfiguration,
          placeholderData: keepPreviousData
        }
      }
    );

  const active = useMemo(
    () => dataSourceConfigurations?.objects?.filter((dsc) => dsc.is_active) ?? [],
    [dataSourceConfigurations?.objects]
  );
  const inactive = useMemo(
    () => dataSourceConfigurations?.objects?.filter((dsc) => !dsc.is_active) ?? [],
    [dataSourceConfigurations?.objects]
  );

  if (dataSourceConfigurations?.objects?.length === 0) {
    return <Navigate to={'/data-sources/all'} />;
  }

  return (
    <div className="flex w-full flex-col gap-6 pt-2 max-md:px-0">
      {!isPendingDataSourceConfigurations ? (
        <>
          {active.length > 0 && (
            <>
              <span className="text-sm font-semibold text-gray-800">Active: {active.length}</span>
              <div className="grid w-full grid-cols-2 gap-6 max-lg:grid-cols-1 2xl:grid-cols-3">
                {active.map((dsc) => (
                  <ConnectedDataSourceCard dataSourceConfiguration={dsc} key={dsc.id} />
                ))}
              </div>
            </>
          )}
          {inactive.length > 0 && (
            <>
              <span className="text-sm font-semibold text-gray-800">Inactive: {inactive.length}</span>
              <div className="grid w-full grid-cols-2 gap-6 max-lg:grid-cols-1 2xl:grid-cols-3">
                {inactive.map((dsc) => (
                  <ConnectedDataSourceCard dataSourceConfiguration={dsc} key={dsc.id} />
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <DataSourceCardGridSkeleton />
      )}
    </div>
  );
});

export default ConnectedDataSourcesPage;
