import { ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import Check from 'assets/check.svg?react';
import Search from 'assets/search.svg?react';
import { Fragment, forwardRef, memo, useCallback, useMemo, useState } from 'react';
import { Portal } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';

interface ValueOptionsDropdownProps {
  options?: readonly ValueOption[];
  minSearchQuery?: number;
  searchable?: boolean;
  floatingStyles: React.CSSProperties | undefined;
}

const ValueOptionsDropdown = memo(
  forwardRef<HTMLDivElement, ValueOptionsDropdownProps>(function ValueOptionsDropdown(
    { options, minSearchQuery, floatingStyles, searchable = false },
    ref
  ) {
    const [searchString, setSearchString] = useState<string | null>(null);

    const filteredOptions = useMemo(() => {
      if (!options) {
        return [];
      } else if (!searchString) {
        return options;
      } else if (!minSearchQuery) {
        return options.filter((option) => option.label.toLowerCase().includes(searchString.toLowerCase()));
      } else if (searchString.length >= minSearchQuery) {
        return options.filter((option) => {
          return option.label.toLowerCase().includes(searchString.toLowerCase());
        });
      }

      return [];
    }, [minSearchQuery, options, searchString]);

    const handleChange = useCallback(function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      setSearchString(event.target.value);
    }, []);

    if (options && options.length >= 10) {
      searchable = true;
    }

    return (
      <Portal>
        <div ref={ref} className="z-50 w-full" style={floatingStyles}>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ListboxOptions className="max-h-60 overflow-y-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none">
              {searchable && (
                <div className="relative px-4 py-3">
                  <Search
                    width={16}
                    height={16}
                    className="pointer-events-none absolute left-7 top-6 fill-gray-700"
                    aria-hidden="true"
                  />
                  <input
                    onKeyDown={stopHeadlessUIPropagation}
                    onChange={handleChange}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 pl-9 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-0"
                    placeholder="Search"
                    type="text"
                  />
                </div>
              )}
              {filteredOptions.map((option) => (
                <ListboxOption
                  key={option.id}
                  className="cursor-pointer select-none px-2 py-1 ui-active:bg-gray-50"
                  value={option}
                >
                  <div className="flex items-center justify-between gap-3 rounded-md px-1.5 py-2 ui-selected:bg-brand-50">
                    <div className="flex items-center gap-2">
                      <span className="block truncate text-sm ui-selected:font-semibold ui-selected:text-brand-800">
                        {option.label}
                      </span>
                    </div>
                    <Check width={20} height={20} className="hidden fill-brand-800 ui-selected:inline" />
                  </div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Portal>
    );
  })
);

function stopHeadlessUIPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  const allowedHeadlessUIKeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Home', 'End', 'Escape'];
  if (!allowedHeadlessUIKeys.includes(event.key)) {
    event.stopPropagation();
  }
}

export default ValueOptionsDropdown;
