import { ComboboxOption, ComboboxOptions, Transition } from '@headlessui/react';
import Check from 'assets/check.svg?react';
import Search from 'assets/search-eye.svg?react';
import { Fragment, forwardRef, memo } from 'react';
import { Portal } from 'utils/helpers';
import { AutocompleteOption } from 'utils/interfaces';

interface AutocompleteOptionsDropdownProps {
  options: readonly AutocompleteOption[];
  floatingStyles: React.CSSProperties | undefined;
}

const AutocompleteOptionsDropdown = memo(
  forwardRef<HTMLDivElement, AutocompleteOptionsDropdownProps>(function AutocompleteOptionsDropdown(
    { options, floatingStyles },
    ref
  ) {
    return (
      <Portal>
        <div ref={ref} className="z-50 w-full" style={floatingStyles}>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ComboboxOptions className="absolute z-50 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none">
              {options.length > 0 ? (
                options.map((option) => (
                  <ComboboxOption
                    key={option.id}
                    value={option}
                    className="cursor-pointer select-none px-2 py-1 ui-active:bg-gray-50"
                  >
                    <div className="flex items-center justify-between gap-3 rounded-md px-1.5 py-2 ui-selected:bg-brand-50">
                      <div className="flex flex-col">
                        <span className="block truncate text-sm font-medium ui-selected:font-semibold ui-selected:text-brand-800">
                          {option.label}
                        </span>
                        <span className="block truncate text-xs text-gray-500 ui-selected:font-semibold ui-selected:text-brand-800">
                          {option.secondaryLabel}
                        </span>
                      </div>
                      <Check width={20} height={20} className="hidden fill-brand-800 ui-selected:inline" />
                    </div>
                  </ComboboxOption>
                ))
              ) : (
                <div className="mx-3 flex items-center justify-center gap-2 p-3">
                  <Search className="size-5 fill-gray-600" />
                  <span className="text-center text-sm">
                    {`Unfortunately, there are no options available for the given search input.`}
                  </span>
                </div>
              )}
            </ComboboxOptions>
          </Transition>
        </div>
      </Portal>
    );
  })
);

export default AutocompleteOptionsDropdown;
